import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { baseApi, coreStore } from '../store';
import { msalConfig } from './config.ts';

export const msalInstance = new PublicClientApplication(msalConfig);

export const useMsalInstance = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    msalInstance.initialize().then(() => {
      setup();
      setIsInitialized(true);
    });
  }, []);

  return isInitialized ? msalInstance : undefined;
};

const authEventTypes: EventType[] = [
  EventType.LOGIN_SUCCESS,
  EventType.ACQUIRE_TOKEN_SUCCESS,
  EventType.SSO_SILENT_SUCCESS,
];

const setup = () => {
  const dispatch = coreStore.dispatch;
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback((event: EventMessage) => {
    if (authEventTypes.includes(event.eventType)) {
      msalInstance.setActiveAccount((event.payload as AuthenticationResult)?.account);
    }
  });

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.LOGOUT_SUCCESS) {
      dispatch(baseApi.util.resetApiState());
    }
  });
};
