export const isEmail = (email: string) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

export const isPhoneNumber = (phoneNumber: string) =>
  /^(\+?[1-9]\d{0,2})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/.test(phoneNumber);

export const isUrlValid = (url: string) => /^[^\s]+(\.[^\s]+)+$/.test(url);

export const validateField = <T extends object>(key: keyof T, value: string) => {
  switch (true) {
    case !value:
      return 'Campul este obligatoriu';
    case key === 'emailAddress' && !isEmail(value):
      return 'Adresa de email nu este valida';
    case key === 'phone' && !isPhoneNumber(value):
      return 'Numarul de telefon nu este valid';
    case key === 'url' && !isUrlValid(value):
      return 'URL invalid';
    default:
      return '';
  }
};
